<template>
  <div class="binding">
    <div class="loginbox">
      <ul class="boxhead">
        <li>
          <span>报名参赛</span>
        </li>
      </ul>
      <div class="boxcontent">
        <div class="loginForm">
          <div class="formcontent">
            <el-form
              label-position="center"
              hide-required-asterisk
              :rules="rules"
              label-width="80px"
              :model="LoginForm"
              ref="LoginForm"
            >
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model.trim="LoginForm.mobile"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" id="spendCode">
                <el-input
                  v-model.trim="LoginForm.code"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  >.
                  <el-button slot="append" v-if="countDownShow"
                    >{{ time }}秒后可重新发送</el-button
                  >
                  <el-button slot="append" v-else @click="codeHandle()"
                    >发送验证码</el-button
                  >
                </el-input>
              </el-form-item>
              <!-- <div class="wechat">
                <img src="../../assets/image/wx.webp" alt="" />
                <a href="http://www.gyycbj.com/Wechat/wchatLoginPc" data-title="weixin">微信登录</a>
              </div> -->
              <el-form-item id="mylogin">
                <el-button
                  id="loginbtn"
                  type="primary"
                  @click="submitForm('LoginForm')"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts">
      <div class="title">大赛咨询</div>
      <div class="conbox">
        <div class="box">
          <p>万老师</p>
          <p>13910648827</p>
          <p>wanwei@gytcare.com</p>
        </div>
        <div class="box">
          <p>邵老师</p>
          <p>13071164003</p>
          <p>zouying@gytcare.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { spendCode, userLogin, bindingUser } from "../API/login.js";
import { setToken } from "../../assets/js/storage";

export default {
  data() {
    return {
      countDownShow: false,
      time: 60, // 倒计时
      // 登录
      LoginForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "change" },
          { min: 6, max: 6, message: "长度应该是6个数字", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 登录发验证码
    async codeHandle() {
      let str = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
      var state = this.LoginForm.mobile.search(str);
      if (this.LoginForm.mobile !== "" && state !== -1) {
        const res = await spendCode({
          mobile: this.LoginForm.mobile,
          type: 3,
        });
        if (res.data.code == "0000") {
          this.$message({
            message: "发送成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
        // 60秒倒计时
        this.countDownShow = true;
        let timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            this.countDownShow = false;
            this.time = 60;
            return clearInterval(timer);
          }
        }, 1000);
      }
      return this.$refs.LoginForm.validateField("mobile");
    },
    // 微信登录
    // weChatLogin() {
    //   // const res = await weChatLogin();
    //   this.$router.push('http://192.168.1.159/gyycwechat/public/index.php/Wechat/wchatLoginPc')
    // },
    // PC登录
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await bindingUser(this.LoginForm);
          if (res.data.code !== "0000") {
            return this.$message(res.data.msg);
          }
          this.$message({
            message: "绑定成功",
            type: "success",
          });
          setToken(res.data.data);
          this.$router.push({ name: "UserInfo" });
        } else {
          return false;
        }
      });
    },
    // PC注册
  },
};
</script>

<style scoped lang="scss">
.binding {
  padding: 80px 0px;
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  .contacts {
    font-weight: bold;
    padding-top: 20px;
    display: none;
    @media screen and (max-width: 540px) {
      display: block;
    }
    text-align: center;
    color: #05258e;
    font-size: 14px;
    .title {
      color: #05258e;
      padding-bottom: 20px;
    }
    .conbox {
      display: flex;
      .box {
        flex: 1;
        line-height: 1.5;
      }
    }
  }
  .loginbox {
    width: 834px;
    margin: 0px auto;
    box-shadow: -10px 0px 32px 32px rgb(24 25 34 / 6%);
    background: #ffffff;
    border-radius: 10px;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    .boxhead {
      width: 100%;
      height: 85px;
      line-height: 80px;
      background: url("../../assets/image/login_bg.webp") no-repeat 100% 100%;
      margin: 0px 0px 20px 0;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      li {
        color: #05258e;
        font-weight: 600;
        font-size: 26px;
        width: 100%;
        height: 100%;
        float: left;
        text-align: center;
        line-height: 85px;
        cursor: pointer;
      }
      .gl {
        color: #5b2d9b;
        position: relative;
        ::after {
          content: "";
          position: absolute;
          left: 50%;
          margin-left: -60px;
          bottom: 15px;
          height: 4px;
          width: 120px;
          background-color: #5b2d9b;
        }
      }
    }
    .boxcontent {
      height: 364px;
      background-color: #ffffff;
      .title {
        text-align: center;
        color: #05258e;
        font-size: 16px;
        padding: 17px 0;
      }
      .loginForm {
        .formcontent {
          padding: 0 160px;
          @media screen and (max-width: 540px) {
            padding: 0 20px;
          }
          .el-form-item {
            height: 60px;
          }
          ::v-deep .el-form-item__label {
            line-height: 60px;
            border: 1px solid #dcdfe6;
            border-right: none;
            height: 60px;
          }
          ::v-deep .el-input__inner {
            line-height: 60px;
            height: 60px;
            border-radius: 0;
          }
          #loginbtn {
            padding: 0;
            width: 510px;
            color: #ffffff;
            font-size: 18px;
            height: 60px;
            background: #293f8e;
            border-radius: 2px;
            @media screen and (max-width: 540px) {
              width: 100%;
            }
          }
          #mylogin {
            margin-top: 30px;
            ::v-deep .el-form-item__content {
              margin-left: 0 !important;
            }
          }
          .wechat {
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
            }
          }
          #spendCode {
            ::v-deep .el-input-group__append {
              color: #5b2d9b;
              cursor: pointer;
            }
          }
        }
      }

      .registerForm {
        padding: 0 160px;
        @media screen and (max-width: 540px) {
          padding: 0 20px;
        }
        .el-form-item {
          height: 60px;
        }
        ::v-deep .el-form-item__label {
          line-height: 60px;
          border: 1px solid #dcdfe6;
          border-right: none;
          height: 60px;
        }
        ::v-deep .el-input__inner {
          line-height: 60px;
          height: 60px;
          border-radius: 0;
        }
        #loginbtn {
          padding: 0;
          width: 510px;
          color: #ffffff;
          font-size: 18px;
          height: 60px;
          background: #293f8e;
          border-radius: 2px;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
        #mylogin {
          margin-top: 30px;
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }
        .wechat {
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        #spendCode {
          ::v-deep .el-input-group__append {
            color: #5b2d9b;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
